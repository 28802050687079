import $ from 'jquery'
import Handsontable from 'handsontable';
import 'handsontable/dist/handsontable.full.css';
let htable_data_changed = false;

function decodeChanges(chg, tableData, colHeaders) {
    const cpos = chg;
    const linha = cpos[0];
    const coluna = cpos[1];
    const novo = cpos[3];
    const atributo = colHeaders[coluna];
    // coluna 0 é sempre o ID e é escondida
    const id = tableData[linha][0]
    let ret = {change: {id: id, atributo: atributo, valor: novo}}
    return JSON.stringify(ret)
}

function htSaveChanges(changes, updateUrl, tableData, colHeaders) {
    changes.forEach(function(change,index) {
        htSaveChange(change, updateUrl, tableData, colHeaders)
        // console.log('salvando #{index} ${change}')
    });
}

function htSaveChange(change, updateUrl, tableData, colHeaders) {
    const input = decodeChanges(change, tableData, colHeaders);
    $.ajax({
        type: 'POST',
        url: updateUrl + '_cell',
        data: input,
        contentType: "application/json; charset=utf-8",
        dataType: 'json',
        success: function (res) {
            console.log('Produtos salvos com sucesso!');
            htable_data_changed = false;
        },
        error: function (xhr) {
            alert(xhr.responseText);
        }
    });
}


$(document).on('turbo:load', function () {
    const htable_element = $("#htable");
    const data = htable_element.data('table-data');
    htable_data_changed = false;
    if (typeof data !== 'undefined') {
        const colHeaders = htable_element.data('col-headers') || true;
        const columns = htable_element.data('col-renderers');
        const readOnly = htable_element.data('readonly');
        const container = document.getElementById('htable');
        let hiddenCols = null
        if (!readOnly) {
            hiddenCols = {columns: [0]}
        }

        const hot = new Handsontable(container, {
            data: data,
            rowHeaders: true,
            colHeaders: colHeaders,
            columns: columns,
            columnSorting: true,
            readOnly: readOnly,
            dropdownMenu: true,
            filters: true,
            width: 'auto',
            height: 'auto',
            hiddenColumns: hiddenCols,
            licenseKey: 'non-commercial-and-evaluation' // for non-commercial use only
        });

        const button = document.querySelector('#export-htable');
        if (button) {
            const exportPlugin = hot.getPlugin('exportFile');
            button.addEventListener('click', () => {
                exportPlugin.downloadFile('csv', {
                    bom: false,
                    columnDelimiter: ',',
                    columnHeaders: true,
                    exportHiddenColumns: false,
                    exportHiddenRows: true,
                    fileExtension: 'csv',
                    filename: 'inovata-CSV-file_[YYYY]-[MM]-[DD]',
                    mimeType: 'text/csv',
                    rowDelimiter: '\r\n',
                    rowHeaders: false
                });
            });
        }

        const updateUrl = htable_element.data('update-url');
        if (typeof updateUrl !== 'undefined') {
            const btnUpdate = $("#save-htable");
            const divUpdate = $("#display-save-htable");
            // salva depois de editar
            hot.addHook('afterChange', function (changes, src) {
                htSaveChanges(changes, updateUrl, data, colHeaders)
                htable_data_changed = true;
                divUpdate.fadeTo(200, 1.0, function () {
                    $(this).fadeTo(750, 0.0);
                });
            })
            // salvo ao clicar no botão
            btnUpdate.click(function () {
                const tableData = JSON.stringify(hot.getData());
                const input = JSON.stringify({"input": tableData});

                $.ajax({
                    type: 'POST',
                    url: updateUrl,
                    data: input,
                    contentType: "application/json; charset=utf-8",
                    dataType: 'json',
                    success: function (res) {
                        alert('Produtos salvos com sucesso!');
                        htable_data_changed = false;
                    },
                    error: function (xhr) {
                        alert(xhr.responseText);
                    }
                });
                btnUpdate.blur();
            });
        }

    }
});

